import React from 'react'
import { LoadingV2Container } from './styled'

const LoadingV2 = () => {
  return (
    <LoadingV2Container>
      <div className="block-loading">
        <img src="/assets/images/Common/logo_loading.png" width={171} height={161} alt="loading" />
      </div>
      {/* <div className="image">
      <img src="/assets/images/Common/loading.gif" width={294} height={274} alt="loading" />
      </div> */}
      <p className='text'>loading...</p>
    </LoadingV2Container>
  )
}

export default LoadingV2