import React, { useContext } from "react";
import {
  IconBottom,
  KeyvisualContainer,
  KeyvisualContentLeft,
  KeyvisualLeft,
  KeyvisualRight,
  KeyvisualWrapperInner,
} from "./styled";
import { WrapperCommon } from "../../../styled";
import TitleCommon from "../../../Component/Title";
import { ContextProviderWrapper } from "../../../Component/Context";

const Keyvisual = () => {
  const { isDesktop } = useContext(ContextProviderWrapper)!;
  return (
    <KeyvisualContainer data-aos="fade-up">
      <WrapperCommon>
        <KeyvisualWrapperInner>
          <KeyvisualLeft>
            <TitleCommon text="Unlock the Full Potential of Blockchain" />
            <KeyvisualContentLeft>
              <div className="logo-left">
                <img
                  src="/assets/images/Keyvisual/keyviusal_logo_01.png"
                  width={100}
                  height={99}
                  alt="Unlock the Full Potential of Blockchain"
                />
              </div>
              <div className="content-wic">
                <div className="logo-chain">
                  <img
                    src="/assets/images/Keyvisual/keyviusal_logo_02.png"
                    width={121}
                    height={39}
                    alt="Unlock the Full Potential of Blockchain"
                  />
                </div>
                <p className="text-left">
                  WIC Chain is a cutting-edge Layer 1 (L1) blockchain platform
                  built to power the future of decentralized applications
                  (dApps) and Web3 ecosystems.
                </p>
              </div>
            </KeyvisualContentLeft>
          </KeyvisualLeft>
          <KeyvisualRight>
            <div className="image-decor-01">
              <img
                src={isDesktop ? "/assets/images/Keyvisual/keyvisual_bg.png" : '/assets/images/Keyvisual/keyvisual_bg_sp.png'}
                width={903}
                height={675}
                loading="eager"
                alt="Unlock the Full Potential of Blockchain"
              />
            </div>
            <div className="image-gif">
              <img src="/assets/images/Keyvisual/icon.gif" alt="" />
            </div>
            <div className="logo-middle">
              <img
                src="/assets/images/Keyvisual/logo_middle_01.png"
                width={288}
                height={288}
                loading="eager"
                alt=""
              />
            </div>
          </KeyvisualRight>
        </KeyvisualWrapperInner>
        <IconBottom>
          <img
            src="/assets/images/Keyvisual/background_decor_02.png"
            width={181}
            alt=""
          />
        </IconBottom>
      </WrapperCommon>
    </KeyvisualContainer>
  );
};

export default Keyvisual;
