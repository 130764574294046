import styled from "styled-components";
import { TitleContainer } from "../../../Component/Title/styled";
import { TextCommon, WrapperCommon } from "../../../styled";

export const FaqContainer = styled.div`
  padding-top: 150px;
  padding-bottom: 150px;
  position: relative;
  z-index: 4;
  ${TitleContainer} {
    text-align: center;
  }
  ${TextCommon} {
    width: 75%;
    margin: 0 auto 30px;
  }
  @media screen and (min-width: 1024px) {
    ${WrapperCommon} {
      min-height: 540px;
    }
  }
  @media screen and (max-width: 1024px) {
    padding: 50px 0;
    ${TextCommon} {
      width: 100%;
    }
  }
`;
export const FaqContent = styled.div`
  display: flex;
  gap: 10px;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;
export const FaqContentLeft = styled.div`
  width: 30%;
  position: relative;
  padding: 16px;
  z-index: 1;
  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(57, 57, 57, 0.53) url("/assets/images/Faq/faq_bg_01.png")
      center / cover no-repeat;
    border-radius: 8px;
    z-index: -1;
  }
  h3 {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    max-width: unset;
  }
`;
export const FaqListCategory = styled.ul``;
export const FaqItemCategory = styled.li`
  text-align: left;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  transition: all 0.26s ease;
  min-height: 20px;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  &:hover {
    color: #fc0;
  }
  &.active {
    color: #fc0;
    text-shadow: 0px 0px 18.1px #ffd773;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.28px;
    span {
      position: relative;
      padding: 0 18px;
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        margin-top: -8px;
        width: 10px;
        height: 16px;
        background: center / contain no-repeat;
      }
      &:before {
        left: 0;
        background-image: url("/assets/images/Faq/faq_icon_01.svg");
      }
      &:after {
        right: 0;
        background-image: url("/assets/images/Faq/faq_icon_02.svg");
      }
    }
  }
`;
export const FaqContentRight = styled.div`
  width: calc(100% - 30%);
  border-radius: 8px;
  background: rgba(46, 46, 46, .9);
  padding: 24px 32px;
  .title-faq {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .ant-collapse-header-text {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
  }
  .ant-collapse {
    border: unset;
  }
  .ant-collapse-content {
    border-top: 0;
    background-color: unset;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-left: 0;
    padding-right: 0;
    .ant-collapse-expand-icon {
      order: 2;
      padding-inline-end: 0;
    }
  }
  .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
    padding-bottom: 10px;
  }
  .ant-collapse > .ant-collapse-item {
    border-color: #5c5c5c;
    &.ant-collapse-item-active {
      .ant-collapse-arrow {
        &:after {
          transform: rotate(0deg);
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;
export const FaqCollapse = styled.div`
  h3 {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
  }
  p {
    color: #b2b2b2;
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    line-height: 1.3;
  }
`;
export const FaqCollapseIcon = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: solid 1px #93989f;
  position: relative;
  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 2px;
    border-radius: 20px;
    background-color: #93989f;
    transition: transform 0.26s ease;
  }
  &:after {
    transform: rotate(90deg);
  }
`;
