import React from "react";
import { TitleContainer } from "./styled";

interface TitleInterFace {
  text: string;
  className?: string;
}
const TitleCommon = ({ text, className }: TitleInterFace) => {
  return (
    <TitleContainer className={className}>
      <span>{text}</span>
    </TitleContainer>
  );
};

export default TitleCommon;
