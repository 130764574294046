import React, { useContext, useRef, useState } from "react";
import {
  TokenomicsContainer,
  TokenomicsLeft,
  TokenomicsList,
  TokenomicsListItem,
  TokenomicsPercent,
  TokenomicsRight,
  TokenomicsWrapperInner,
} from "./styled";
import { TextCommon, WrapperCommon } from "../../../styled";
import TitleCommon from "../../../Component/Title";
import Chart from "react-google-charts";
import { ContextProviderWrapper } from "../../../Component/Context";

export const data = [
  ["Task", "Percentage"],
  ["Team", 12],
  ["Advisor", 5],
  ["Marketing", 8],
  ["Reward", 15],
  ["Airdrop", 5],
  ["Production", 22],
  ["Dev Fund", 5],
  ["Seed", 5],
  ["Private", 8],
  ["IDO", 2],
  ["Partner", 6],
  ["Liquidity", 7],
];

export const options = {
  title: "",
  is3D: true,
  colors: [
    "#FFF9E1",
    "#FFECB2",
    "#FFE083",
    "#FFD54F",
    "#FFC928",
    "#FFC205",
    "#FFB200",
    "#FEA000",
    "#FF8E01",
    "#FF7000",
    "#FF5F08",
    "#FF4217",
  ], // Specify your colors
  pieSliceBorderColor: "#000",
  pieHole: 1,
  tooltip: {
    trigger: "focus", // Ensure tooltips appear when hovering
  },
  legend: {
    position: "right",
    alignment: "center",
    textStyle: {
      color: "#9C9C9C",
      fontSize: 14,
    },
  },
  pieSliceTextStyle: {
    color: "#000", // Set your desired text color here
    fontSize: 12,
  },
};

const Tokenomics = () => {
  const chartRef = useRef<any>();
  const [valueNumber, setValueNumber] = useState(12);
  const [valueText, setValueText] = useState("Team");
  const { isDesktop } = useContext(ContextProviderWrapper)!;

  const handleSelect = () => {
    const chart = chartRef.current;
    const selection = chart.getChart().getSelection();

    if (selection.length > 0) {
      const selectedItem: any = selection[0]; // Get the selected item
      const taskName: any = data[selectedItem.row + 1][0]; // Get task name based on the row (data includes header)
      const percentage: any = data[selectedItem.row + 1][1]; // Get the percentage value

      // Update state values
      setValueText(taskName);
      setValueNumber(percentage);
    }
  };

  return (
    <TokenomicsContainer id="token">
      <WrapperCommon>
        <TokenomicsWrapperInner>
          <TokenomicsLeft data-aos="fade-up-right">
            <TitleCommon text="WIC Coin Tokenomics" />
            <TextCommon>
              The Wiccoin stands at the heart of the WICCHAIN ecosystem
            </TextCommon>
            <TokenomicsList>
              {listToken.map((item: any, index: number) => (
                <TokenomicsListItem key={index}>
                  <span className="text-left">{item.textLeft}</span>
                  <span className="text-right">{item.textRight}</span>
                </TokenomicsListItem>
              ))}
            </TokenomicsList>
          </TokenomicsLeft>
          {!isDesktop && (
            <TokenomicsPercent>
              <span className="number">{valueNumber}%</span>
              <p className="text">{valueText}</p>
            </TokenomicsPercent>
          )}
          <TokenomicsRight data-aos="fade-up-left" data-aos-delay="400">
            {isDesktop && (
              <TokenomicsPercent>
                <span className="number">{valueNumber}%</span>
                <p className="text">{valueText}</p>
              </TokenomicsPercent>
            )}

            <Chart
              chartType="PieChart"
              data={data}
              options={options}
              width={isDesktop ? "100%" : "125%"}
              height={isDesktop ? "500px" : "450px"}
              className="tokenomics-chart"
              chartEvents={[
                {
                  eventName: "select", // Detect selection of slice
                  callback: ({ chartWrapper }) => {
                    chartRef.current = chartWrapper;
                    handleSelect(); // Trigger slice selection
                  },
                },
              ]}
            />
          </TokenomicsRight>
        </TokenomicsWrapperInner>
      </WrapperCommon>
    </TokenomicsContainer>
  );
};

const listToken = [
  {
    textLeft: "Token Name:",
    textRight: "WIC Coin",
  },
  {
    textLeft: "Token Platform:",
    textRight: "WIC Chain",
  },
  {
    textLeft: "Token Symbol:",
    textRight: "WIC",
  },
  {
    textLeft: "Token Standard:",
    textRight: "W-20",
  },
  {
    textLeft: "Max Supply:",
    textRight: "10,000,000,000",
  },
];

export default Tokenomics;
