import React from "react";
import { PartnerContainer, PartnerList, PartnerListItem } from "./styled";
import { TextCommon, WrapperCommon } from "../../../styled";
import TitleCommon from "../../../Component/Title";
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";

const Partner = () => {
  return (
    <PartnerContainer>
      <WrapperCommon>
        <div data-aos="fade-down">
          <TitleCommon text="Partner & Backer" />
          <TextCommon>
            WICCHAIN has cooperation with many financial institutions and
            blockchains widely and always looks forward to expanding links in
            the future when WICCHAIN mainnet is launched.
          </TextCommon>
        </div>
        <PartnerList data-aos="zoom-in-up">
          <Marquee autoFill pauseOnHover={true}>
            {listMarquee.map((item: any, index: number) => (
              <PartnerListItem key={index}>
                <Link to={item.href} target="_blank">
                  <img
                    src={item.icon}
                    width={199}
                    height={86}
                    loading="eager"
                    alt="Banner"
                    className="image-normal"
                  />
                  {/* <img
                    src={item.iconHover}
                    width={199}
                    height={86}
                    loading="eager"
                    alt="Banner"
                    className="image-hover"
                  /> */}
                </Link>
              </PartnerListItem>
            ))}
          </Marquee>
        </PartnerList>
      </WrapperCommon>
    </PartnerContainer>
  );
};

const listMarquee = [
  {
    icon: "/assets/images/Partner/icon_partner_01.png",
    iconHover: "/assets/images/Partner/icon_partner_01_hover.png",
    href: "https://aws.amazon.com/",
  },
  {
    icon: "/assets/images/Partner/icon_partner_02.png",
    iconHover: "/assets/images/Partner/icon_partner_02_hover.png",
    href: "https://www.infura.io/",
  },
  {
    icon: "/assets/images/Partner/icon_partner_03.png",
    iconHover: "/assets/images/Partner/icon_partner_03_hover.png",
    href: "https://cloud.google.com/",
  },
  {
    icon: "/assets/images/Partner/icon_partner_04.png",
    iconHover: "/assets/images/Partner/icon_partner_04_hover.png",
    href: "https://www.alchemy.com/",
  },
  {
    icon: "/assets/images/Partner/icon_partner_05.png",
    iconHover: "/assets/images/Partner/icon_partner_05_hover.png",
    href: "https://www.helius.dev/",
  },
  {
    icon: "/assets/images/Partner/icon_partner_06.png",
    iconHover: "/assets/images/Partner/icon_partner_06_hover.png",
    href: "https://magiceden.io/",
  },
  {
    icon: "/assets/images/Partner/icon_partner_07.png",
    iconHover: "/assets/images/Partner/icon_partner_07_hover.png",
    href: "https://metamask.io/",
  },
  {
    icon: "/assets/images/Partner/icon_partner_08.png",
    iconHover: "/assets/images/Partner/icon_partner_08_hover.png",
    href: "https://trustwallet.com/",
  },
  {
    icon: "/assets/images/Partner/icon_partner_09.png",
    iconHover: "/assets/images/Partner/icon_partner_09_hover.png",
    href: "https://www.safepal.com/",
  },
  {
    icon: "/assets/images/Partner/icon_partner_10.png",
    iconHover: "/assets/images/Partner/icon_partner_10_hover.png",
    href: "https://tiplink.io/",
  },
];

export default Partner;
