import styled from "styled-components";
import { TitleContainer } from "../../../Component/Title/styled";
import { TextCommon } from "../../../styled";

export const PartnerContainer = styled.div`
  padding-top: 150px;
  ${TitleContainer} {
    text-align: center;
  }
  ${TextCommon} {
    width: 75%;
    margin: 0 auto 30px;
  }
  @media screen and (max-width: 1024px) {
    padding-top: 85px;
    ${TextCommon} {
      width: 100%;
    }
  }
`;
export const PartnerList = styled.div``;
export const PartnerListItem = styled.div`
  margin-right: 10px;
  width: 199px;
  a {
    display: inline-block;
    position: relative;
    width: 199px;
    height: 86px;
    /* &:hover {
      .image-hover {
        opacity: 1;
      }
      .image-normal {
        opacity: 0;
      }
    } */
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      transition: opacity .26s ease;
      border-radius: 8px;
    }
  }
  /* .image-hover {
    opacity: 0;
  } */
  @media screen and (max-width: 1024px) {
    width: 199px;
    height: 86px;
  }
`;
