import styled from "styled-components";

export const HeaderContainer = styled.div`
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(12.5px);
  border-bottom: solid 1px rgba(255, 255, 255, 0.12);
  padding: 18px;
  margin-bottom: 10px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 110;
  .button-launch {
    padding: 0;
    a {
      padding: 12px 50px 12px 20px;
      display: inline-block;
    }
  }
  @media screen and (max-width: 1024px) {
    border-bottom: none;
    .button-launch {
      padding: 0;
      a {
        padding: 10px 14px;
      }
    }
  }
`;
export const HeaderContent = styled.div`
  .logo {
    width: 117px;
    img {
      width: 100%;
    }
  }
`;
export const ButtonLaunchApp = styled.button`
  border-radius: 8px;
  color: #fff;
  color: rgb(255, 255, 255);
  border: 1px solid rgb(230, 229, 229);
  background: linear-gradient(rgb(110, 86, 37) 0%, rgb(236, 181, 0) 100%);
  box-shadow: rgba(217, 217, 217, 0.9) 0px 3px 5px 0px inset,
    rgba(217, 217, 217, 0.9) 0px -1px 5px 0px inset;
  padding: 12px 50px 12px 20px;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  position: relative;
  &:after {
    position: absolute;
    content: "";
    top: 50%;
    margin-top: -12px;
    right: 10px;
    background: url("/assets/images/Common/Header/header_icon_arrow.png") center /
      cover no-repeat;
    width: 24px;
    height: 24px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 13px;
    padding: 10px 14px;
    &:after {
      content: none;
    }
  }
`;
export const ListNavigation = styled.ul`
  transition: transform 0.26s ease;
  @media screen and (max-width: 1024px) {
    top: 0;
    left: 0;
    height: 100vh;
    transform: translateX(-100%);
    background-color: #2f2f2f;
  }
`;
export const ContentNavigation = styled.div`
  @media screen and (max-width: 1024px) {
    &.show-navigation {
      ${ListNavigation} {
        transform: translateX(0);
      }
    }
  }
`;
export const ListNavigationItem = styled.li`
  a {
    transition: all 0.26s ease;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    &:hover {
      color: #fc0;
      text-shadow: 0px 0px 18.1px #ffd773;
    }
    &.active {
      color: #fc0;
      text-shadow: 0px 0px 18.1px #ffd773;
    }
  }
  @media screen and (max-width: 1024px) {
    a {
      color: #fff;
    }
  }
`;

export const ContentButtonMenu = styled.div``;
export const ButtonMenu = styled.button`
  width: 38px;
  height: 38px;
  border: solid 1px #3e3e41;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
  position: relative;
  z-index: 1111;
  span {
    transition: all 0.26s ease;
    display: block;
    width: 20px;
    height: 2px;
    background-color: #fff;
  }
  &.active {
    span {
      &:nth-child(1) {
        transform: rotateZ(45deg) translate(5px, 5px);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        transform: rotateZ(-45deg) translate(4px, -5px);
      }
    }
  }
`;
