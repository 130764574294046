import React, { useContext } from "react";
import {
  FeaturesContainer,
  FeaturesDecor,
  FeaturesIcon,
  FeaturesInner,
  FeaturesInnerLeft,
  FeaturesList,
  FeaturesText,
  FeaturesTitle,
  ItemList,
  SlideMobile,
} from "./styled";
import { TextCommon, WrapperCommon } from "../../../styled";
import TitleCommon from "../../../Component/Title";
import { ContextProviderWrapper } from "../../../Component/Context";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Mousewheel, Keyboard } from "swiper/modules";

const Features = () => {
  const { isDesktop } = useContext(ContextProviderWrapper)!;
  return (
    <FeaturesContainer id="features">
      <WrapperCommon>
        <div data-aos="fade-down">
          <TitleCommon text="Key Features"/>
          <TextCommon data-aos="fade-down">
            WIC Chain: EVM-compatible, scalable, low-cost, and cross-chain
            interoperable.
          </TextCommon>
        </div>
        <FeaturesInner>
          {isDesktop && (
            <FeaturesInnerLeft data-aos="fade-right">
              <FeaturesIcon>
                <img
                  src="/assets/images/Features/features_icon_01.png"
                  width={64}
                  height={64}
                  loading="lazy"
                  alt=""
                />
              </FeaturesIcon>
              <FeaturesTitle>Developer-Friendly Tools</FeaturesTitle>
              <FeaturesText>
                Full support for Solidity, Rust, Remix, and other Ethereum
                development tools.
              </FeaturesText>
              <div className="features-img">
                <img
                  src="/assets/images/Features/features_img_01.png"
                  width={483}
                  alt=""
                />
              </div>
            </FeaturesInnerLeft>
          )}

          {isDesktop ? (
            <FeaturesList>
              {listFeatures.map((item: any, index: number) => (
                <ItemList key={index} data-aos="fade-left" data-aos-delay={item.delay}>
                  <FeaturesIcon>
                    <img
                      src={item.icon}
                      alt={item.title}
                      width={64}
                      height={64}
                      loading="lazy"
                    />
                  </FeaturesIcon>
                  <FeaturesTitle>{item.title}</FeaturesTitle>
                  <FeaturesText>{item.text}</FeaturesText>
                </ItemList>
              ))}
            </FeaturesList>
          ) : (
            <SlideMobile>
              <Swiper
                spaceBetween={20}
                grabCursor={true}
                slidesPerView={2}
                className="slide-mobile"
                loop={true}
                modules={[Navigation, Mousewheel, Keyboard]}
                navigation={true}
              >
                {listFeatures.map((item: any, index: number) => (
                  <SwiperSlide>
                    <ItemList key={index}>
                      <FeaturesIcon>
                        <img
                          src={item.icon}
                          alt={item.title}
                          width={64}
                          height={64}
                          loading="lazy"
                        />
                      </FeaturesIcon>
                      <FeaturesTitle>{item.title}</FeaturesTitle>
                      <FeaturesText>{item.text}</FeaturesText>
                    </ItemList>
                  </SwiperSlide>
                ))}
              </Swiper>
            </SlideMobile>
          )}
          {!isDesktop && (
            <FeaturesDecor>
              <img
                src="/assets/images/Features/features_img_02.png"
                width={309}
                alt=""
              />
            </FeaturesDecor>
          )}
        </FeaturesInner>
      </WrapperCommon>
    </FeaturesContainer>
  );
};

const listFeatures = [
  {
    icon: "/assets/images/Features/features_icon_01.png",
    title: "Developer-Friendly  Tools",
    text: "Full support for Solidity, Rust, Remix, and other Ethereum development tools.",
    delay: '0'
  },
  {
    icon: "/assets/images/Features/features_icon_02.png",
    title: "Eco-Friendly  Consensus",
    text: "Energy-efficient mechanisms that minimize environmental impact.",
    delay: '100'
  },
  {
    icon: "/assets/images/Features/features_icon_03.png",
    title: "Instant  Finality",
    text: "Near-instant transaction confirmation for a smoother user experience.",
    delay: '200'
  },
  {
    icon: "/assets/images/Features/features_icon_04.png",
    title: "Governance  Model",
    text: "Community-driven governance ensuring decentralized decision-making.",
    delay: '300'
  },
  {
    icon: "/assets/images/Features/features_icon_05.png",
    title: "Robust  Security Layer",
    text: "Advanced cryptographic protocols to ensure data integrity and user privacy.",
    delay: '400'
  },
];

export default Features;
