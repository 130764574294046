import React, { useContext } from "react";
import {
  VisionButton,
  VisionContainer,
  VisionList,
  VisionListItem,
} from "./styled";
import { WrapperCommon } from "../../../styled";
import TitleCommon from "../../../Component/Title";
import { ContextProviderWrapper } from "../../../Component/Context";

const Vision = () => {
  const { isDesktop } = useContext(ContextProviderWrapper)!;
  return (
    <VisionContainer id="overview">
      <WrapperCommon>
        <VisionList>
          <VisionListItem data-aos="fade-up">
            <div className="content-left">
              <TitleCommon text="WIC Vision" />
              <p className="text-left">
                To build an advanced decentralized blockchain platform that
                delivers interoperability, high performance, and low costs,
                driving the growth of the global dApp and Web3 ecosystem.
              </p>
              <VisionButton>BUILD NOW</VisionButton>
            </div>
            <div className="content-right">
              <img
                src={`${isDesktop ? '/assets/images/Vision/Vision_img_01.png' : '/assets/images/Vision/Vision_img_01_sp.png'}`}
                width={1121}
                alt=""
                className="image-right"
              />
            </div>
          </VisionListItem>
          <VisionListItem className="is-reverst" data-aos="fade-up" data-aos-delay="100">
            <div className="content-left">
              <TitleCommon text="For Developers" />
              <p className="text-left">
                Build your next-gen dApp on WIC Chain using Solidity, Truffle,
                Hardhat, or any Ethereum development framework. Experience the
                power of an L1 chain designed for efficiency, scalability, and
                ease of use.
              </p>
            </div>
            <div className="content-right">
              <img
                src={`${isDesktop ? '/assets/images/Vision/Vision_img_02.png' : '/assets/images/Vision/Vision_img_02_sp.png'}`}
                width={1121}
                alt=""
              />
            </div>
          </VisionListItem>
        </VisionList>
      </WrapperCommon>
    </VisionContainer>
  );
};

export default Vision;
