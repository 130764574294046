import styled from "styled-components";

export const TitleContainer = styled.h2`
  margin-bottom: 10px;
  span {
    display: inline-block;
    background: var(--GRA, linear-gradient(270deg, #FEDF7A 0%, #ECB131 27.45%, #FFFF8F 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 50px;
    font-weight: 600;
    letter-spacing: 0.28px;
    text-transform: capitalize;
  }
  @media screen and (max-width: 1024px) {
    span {
      font-size: 24px;
    }
  }
`