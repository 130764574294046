import styled from "styled-components";
import { TitleContainer } from "../../../Component/Title/styled";
import { TextCommon } from "../../../styled";

export const FeaturesContainer = styled.div`
  padding: 150px 0 0;
  position: relative;
  z-index: 4;
  ${TitleContainer} {
    text-align: center;
  }
  ${TextCommon} {
    width: 38%;
    margin: 0 auto 30px;
  }
  @media screen and (max-width: 1024px) {
    padding-top: 85px;
    padding-bottom: 150px;
    position: relative;
    ${TextCommon} {
      width: 100%;
    }
  }
`;
export const FeaturesInner = styled.div`
  @media screen and (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    position: relative;
  }
`;
export const FeaturesInnerLeft = styled.div`
  position: relative;
  z-index: 1;
  max-width: 357px;
  width: 100%;
  border-radius: 10px;
  padding: 41px 32px;
  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("/assets/images/Features/features_bg_02.png") center / cover
      no-repeat;
    z-index: -1;
  }
  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    background: url("/assets/images/Features/features_bg.png") center / cover
      no-repeat;
    width: 357px;
    height: 365px;
    mix-blend-mode: plus-lighter;
  }
  .features-img {
    position: absolute;
    bottom: -150px;
    left: 50%;
    transform: translateX(-50%);
    width: 483px;
    margin: 0 auto;
  }
`;
export const FeaturesIcon = styled.div`
  margin-bottom: 20px;
`;
export const FeaturesTitle = styled.h3`
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
  @media screen and (max-width: 1024px) {
    font-size: 16px;
    min-height: 42px;
  }
`;
export const FeaturesText = styled.p`
  color: rgba(255, 255, 255, 0.5);
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  @media screen and (max-width: 1024px) {
    font-size: 13px;
  }
`;
export const FeaturesList = styled.div`
  ${FeaturesIcon} {
    margin-bottom: 80px;
  }
  @media screen and (min-width: 1024px) {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
`;

export const ItemList = styled.div`
  width: calc(100% / 2 - 15px);
  position: relative;
  z-index: 1;
  border-radius: 10px;
  overflow: hidden;
  min-height: 343px;
  padding: 40px 30px;
  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    background: url("/assets/images/Features/features_bg.png") center / cover
      no-repeat;
    width: 357px;
    height: 365px;
    mix-blend-mode: plus-lighter;
    pointer-events: none;
  }
  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("/assets/images/Features/features_bg_03.png") center / cover
      no-repeat;
    z-index: -1;
  }
  @media screen and (min-width: 1024px) {
    &:nth-child(1) {
      display: none;
    }
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    min-height: 235px;
  }
`;
export const SlideMobile = styled.div`
  @media screen and (max-width: 1024px) {
    margin-right: -100px;
    .slide-mobile {
      padding-bottom: 100px;
    }
    .swiper-button-next {
      top: unset;
      bottom: 0;
      right: 98px;
      left: unset;
      width: 42px;
      height: 42px;
      &:after {
        background: url("/assets/images/Features/icon_arrow_right.png") center /
          contain no-repeat;
        font-family: unset;
        content: "";
        width: 42px;
        height: 42px;
      }
    }
    .swiper-button-prev {
      top: unset;
      bottom: 0;
      right: 150px;
      left: unset;
      width: 42px;
      height: 42px;
      &:after {
        background: url("/assets/images/Features/icon_arrow_left.png") center /
          contain no-repeat;
        font-family: unset;
        content: "";
        width: 42px;
        height: 42px;
      }
    }
  }
`;
export const FeaturesDecor = styled.div`
  position: absolute;
  bottom: 10px;
  left: 0;
`;
