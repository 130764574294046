import styled from "styled-components";
import { WrapperCommon } from "../../../styled";

export const ChooseContainer = styled.div`
  padding-top: 150px;
  position: relative;
  z-index: 4;
  ${WrapperCommon} {
    border-color: #595959;
    background: url("/assets/images/Choose/choose_bg.png") bottom center / cover
      no-repeat;
    position: relative;
  }
  @media screen and (max-width: 1024px) {
    padding-top: 85px;
    padding-left: 12px;
    padding-right: 12px;
    ${WrapperCommon} {
      background-image: url("/assets/images/Choose/choose_bg_sp.png");
      padding-left: 0;
      padding-right: 0;
      background-position: bottom -170px center;
    }
  }
`;
export const WrapperInner = styled.div`
  padding: 75px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    top: -1000px;
    right: -640px;
    width: 1000px;
    height: 1000px;
    background: rgba(255, 255, 255, 0.69);
    filter: blur(402.678955078125px);
  }
  @media screen and (max-width: 1024px) {
    padding: 26px 12px;
  }
`;
export const ChooseHeading = styled.div`
  @media screen and (max-width: 1024px) {
    align-items: flex-end;
    margin-bottom: 50px;
  }
`;
export const HeadingLeft = styled.div`
  @media screen and (max-width: 1024px) {
    width: 75%;
  }
`;
export const ChooseHeadingImage = styled.figure`
  @media screen and (max-width: 1024px) {
    width: 70px;
    padding-bottom: 30px;
  }
`;
export const ChooseText = styled.p`
  color: rgba(255, 255, 255, 0.7);
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.28px;
  margin-bottom: 28px;
  @media screen and (max-width: 1024px) {
    font-size: 15px;
  }
`;
export const ChooseList = styled.ul`
  @media screen and (max-width: 1024px) {
    padding: 0 10px;
  }
`;
export const ChooseListItem = styled.li`
  margin-bottom: 40px;
  min-height: 207px;
  .icon {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10.25px;
    box-shadow: 0px 5.125px 30.75px 0px rgba(0, 0, 0, 0.3);
    margin-bottom: 24px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .title {
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .text {
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    letter-spacing: 0.28px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
  }
  @media screen and (max-width: 1024px) {
    width: calc(100% / 2);
    min-height: unset;
    .icon {
      width: 32px;
      height: 32px;
      margin-bottom: 12px;
    }
    .title {
      font-size: 14px;
      margin-bottom: 10px;
    }
    .text {
      font-size: 13px;
    }
  }
`;
