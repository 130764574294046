import React, { useState } from "react";
import {
  FaqCollapse,
  FaqCollapseIcon,
  FaqContainer,
  FaqContent,
  FaqContentLeft,
  FaqContentRight,
  FaqItemCategory,
  FaqListCategory,
} from "./styled";
import { TextCommon, WrapperCommon } from "../../../styled";
import TitleCommon from "../../../Component/Title";
import { Collapse, CollapseProps } from "antd";

const Faq = () => {
  const [activeFaq, setActiveFaq] = useState(1);

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: "What is WIC Chain?",
      children: (
        <FaqCollapse>
          <p>
            WIC Chain is a Layer 1 (L1) blockchain platform compatible with the
            EVM, designed to support dApps and the Web3 ecosystem, offering high
            scalability, security, and low transaction costs.
          </p>
        </FaqCollapse>
      ),
    },
    {
      key: "2",
      label: "Is WIC Chain compatible with Ethereum applications?",
      children: (
        <FaqCollapse>
          <p>
            Yes, WIC Chain is fully compatible with the Ethereum Virtual Machine
            (EVM), allowing developers to easily deploy and migrate dApps and
            smart contracts from Ethereum without code modification.
          </p>
        </FaqCollapse>
      ),
    },
    {
      key: "3",
      label: "What are the transaction fees on WIC Chain?",
      children: (
        <FaqCollapse>
          <p>
            WIC Chain is designed with low transaction fees, ensuring fast
            processing and cost-efficiency for both users and developers.
          </p>
        </FaqCollapse>
      ),
    },
    {
      key: "4",
      label: "What development tools does WIC Chain support?",
      children: (
        <FaqCollapse>
          <p>
            WIC Chain supports popular development tools like Solidity, Remix,
            Truffle, and Hardhat, making it easy for developers to build and
            deploy dApps on the platform.
          </p>
        </FaqCollapse>
      ),
    },
    {
      key: "5",
      label: "How can I participate in the WIC Chain network?",
      children: (
        <FaqCollapse>
          <p>
            Users can participate in the WIC Chain network by staking, becoming
            validators, or running nodes. Developers can also get started by
            deploying dApps using WIC Chain’s developer documentation and tools.
          </p>
        </FaqCollapse>
      ),
    },
  ];
  const items2: CollapseProps["items"] = [
    {
      key: "1",
      label: "How does WIC Chain ensure security?",
      children: (
        <FaqCollapse>
          <p>
            WIC Chain employs a decentralized consensus mechanism and advanced
            cryptographic protocols to ensure network security, protecting user
            data and transactions from potential attacks.
          </p>
        </FaqCollapse>
      ),
    },
    {
      key: "2",
      label: "What consensus algorithm does WIC Chain use?",
      children: (
        <FaqCollapse>
          <p>
            WIC Chain uses a secure and energy-efficient consensus algorithm to
            maintain decentralization and enhance the speed and scalability of
            the network.
          </p>
        </FaqCollapse>
      ),
    },
    {
      key: "3",
      label: "Can I stake tokens on WIC Chain?",
      children: (
        <FaqCollapse>
          <p>
            Yes, WIC Chain supports staking. Users can stake WIC tokens to
            participate in securing the network and earn rewards for validating
            transactions.
          </p>
        </FaqCollapse>
      ),
    },
    {
      key: "4",
      label: "What industries can benefit from WIC Chain?",
      children: (
        <FaqCollapse>
          <p>
            WIC Chain is designed to support a wide range of industries,
            including DeFi, NFTs, gaming, supply chain management, and more,
            enabling innovative applications and business solutions.
          </p>
        </FaqCollapse>
      ),
    },
    {
      key: "5",
      label: "How can developers get support for building on WIC Chain?",
      children: (
        <FaqCollapse>
          <p>
            Developers can access comprehensive documentation, join the
            developer community, and utilize the available SDKs and APIs to get
            started on building and deploying dApps on WIC Chain.
          </p>
        </FaqCollapse>
      ),
    },
  ];
  const items3: CollapseProps["items"] = [
    {
      key: "1",
      label: "What is the native token of WIC Chain?",
      children: (
        <FaqCollapse>
          <p>
            The native token of WIC Chain is WIC, which is used for transaction
            fees, staking, and governance.
          </p>
        </FaqCollapse>
      ),
    },
    {
      key: "2",
      label: "How is the token supply of WIC distributed?",
      children: (
        <FaqCollapse>
          <p>
            WIC token distribution includes allocations for early Advisor,
            Investor, Team & Founder, Ecosystem Development, staking rewards,
            and community incentives.
          </p>
        </FaqCollapse>
      ),
    },
    {
      key: "3",
      label: "How can users participate in WIC Chain governance?",
      children: (
        <FaqCollapse>
          <p>
            WIC holders can participate in governance by voting on protocol
            upgrades, network parameters, and other critical decisions using the
            on-chain governance system.
          </p>
        </FaqCollapse>
      ),
    },
    {
      key: "4",
      label: "What are the staking rewards on WIC Chain?",
      children: (
        <FaqCollapse>
          <p>
            Staking rewards are distributed to validators and delegators based
            on their contribution to securing the network, with rewards varying
            depending on the amount staked and network conditions.
          </p>
        </FaqCollapse>
      ),
    },
    {
      key: "5",
      label: "Will WIC Chain have a deflationary token model?",
      children: (
        <FaqCollapse>
          <p>
            Yes, WIC Chain includes mechanisms to burn a portion of transaction
            fees, contributing to a deflationary token model that reduces supply
            over time.
          </p>
        </FaqCollapse>
      ),
    },
  ];

  const handleChangeCollapse = (id: number) => {
    setActiveFaq(id);
  };

  return (
    <FaqContainer data-aos="zoom-out-up" id="faq">
      <WrapperCommon>
        <TitleCommon text="FAQs" />
        <TextCommon>
          WICCHAIN has cooperation with many financial institutions and
          blockchains widely and always looks forward to expanding links in the
          future when WICCHAIN mainnet is launched.
        </TextCommon>
        <FaqContent>
          <FaqContentLeft>
            <h3 className="title-left">FAQ CATEGORIES</h3>
            <FaqListCategory>
              {listCategory.map((item: any, index: number) => (
                <FaqItemCategory
                  key={index}
                  onClick={() => handleChangeCollapse(item.id)}
                  className={activeFaq === item.id ? 'active' : ''}
                >
                  <span>{item.text}</span>
                </FaqItemCategory>
              ))}
            </FaqListCategory>
          </FaqContentLeft>
          <FaqContentRight>
            <h3 className="title-faq">Recently Questions</h3>

            {activeFaq === 1 ? (
              <Collapse
                items={items}
                defaultActiveKey={["1"]}
                expandIcon={() => <FaqCollapseIcon />}
              />
            ) : activeFaq === 2 ? (
              <Collapse
                items={items2}
                defaultActiveKey={["1"]}
                expandIcon={() => <FaqCollapseIcon />}
              />
            ) : (
              <Collapse
                items={items3}
                defaultActiveKey={["1"]}
                expandIcon={() => <FaqCollapseIcon />}
              />
            )}
          </FaqContentRight>
        </FaqContent>
      </WrapperCommon>
    </FaqContainer>
  );
};

const listCategory = [
  {
    id: 1,
    text: "What is WIC Chain?",
  },
  {
    id: 2,
    text: "Network & Participation",
  },
  {
    id: 3,
    text: "Tokenomics & Governance",
  },
];

export default Faq;
