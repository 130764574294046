import styled from "styled-components";

export const Page404Logo = styled.div`
  margin-bottom: 20px;
`;
export const Page404Title = styled.h1`
  margin-bottom: 20px;
`;
export const Page404SubTitle = styled.h2`
  color: #fff27a;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.28px;
  text-transform: capitalize;
  margin-bottom: 10px;
  @media screen and (max-width: 1024px) {
    font-size: 25px;
  }
`;
export const Page404Text = styled.p`
  color: rgba(255, 255, 255, 0.7);
  font-family: "Radio Canada Big";
  font-size: 16px;
  @media screen and (min-width: 1024px) {
    width: 31%;
    margin: 10px auto 30px;
  }
`;
export const LinkCommon = styled.div`
  a {
    margin-top: 20px;
    font-family: "Radio Canada Big";
    font-size: 15px;
    font-weight: 500;
    border-radius: 8px;
    color: rgb(136, 136, 136);
    border: 1px solid rgba(121, 121, 121, 0.5);
    background: rgba(71, 71, 71, 0.13);
    box-shadow: rgba(255, 255, 255, 0.2) 0px 1px 5px 0px inset,
      rgba(255, 255, 255, 0.2) 0px -1px 5px 0px inset;
    backdrop-filter: blur(2.25px);
    width: 144px;
    height: 48px;
    position: relative;
    padding-right: 25px;
    transition: 0.26s linear;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    &:hover {
      color: rgb(255, 255, 255);
      border: 1px solid rgb(230, 229, 229);
      background: linear-gradient(rgb(110, 86, 37) 0%, rgb(236, 181, 0) 100%);
      box-shadow: rgba(217, 217, 217, 0.9) 0px 3px 5px 0px inset,
        rgba(217, 217, 217, 0.9) 0px -1px 5px 0;
    }
    &:after {
      position: absolute;
      content: "";
      top: 50%;
      margin-top: -13px;
      right: 12px;
      background: url("/assets/images/Common/Header/header_icon_arrow.png")
        center / cover no-repeat;
      width: 24px;
      height: 24px;
    }
  }
`;

export const BlockLogo = styled.div`
  position: relative;
  .number-zero {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 90px;
    margin-left: -107px;
    animation: animate 3s linear infinite;
    border-radius: 50%;
  }
  @keyframes animate {
    0% {
      transform: translateY(0);
      background: linear-gradient(
        180deg,
        #ffff8f 13.67%,
        #ffe983 30.95%,
        #f8d063 49.4%,
        #ffd600 69.03%,
        #fddb74 92.19%
      );
      filter: drop-shadow(0px 0px 50px #ffc300);
    }
    50% {
      transform: translateY(-20px);
      background: linear-gradient(
        180deg,
        #ffff8f 13.67%,
        #ffe983 30.95%,
        #f8d063 49.4%,
        #ffd600 69.03%,
        #fddb74 92.19%
      );
      filter: drop-shadow(0px 0px 120px #ffc300);
    }
    100% {
      transform: translateY(0);
      background: linear-gradient(
        180deg,
        #ffff8f 13.67%,
        #ffe983 30.95%,
        #f8d063 49.4%,
        #ffd600 69.03%,
        #fddb74 92.19%
      );
      filter: drop-shadow(0px 0px 50px #ffc300);
    }
  }
  @media screen and (max-width: 1024px) {
    .number-zero {
      width: 129px;
      height: 127px;
      margin-left: -65px;
    }
  }
`;

export const Circle = styled.div`
  mix-blend-mode: screen;
  animation: animateRotate 4s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  /* margin-top: -102px;
  margin-left: -107px; */
  /* width: 500px;
  height: 500px; */
  @keyframes animateRotate {
    0% {
      transform: rotate(0) translateY(0);
    }
    100% {
      transform: rotate(-360deg) translateY(-20px);
    }
  }
`;
export const Page404Container = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  background: url("/assets/images/common/bg_404.png") center / cover no-repeat;
  margin-top: -93px;
  &.is-403 {
    background-image: url("/assets/images/Common/bg_403.png");
    .number-zero {
      margin-top: -102px;
    }
    @media screen and (max-width: 1024px) {
      .number-zero {
        margin-top: -62px;
      }
    }
  }
`;
