import React from "react";
import {
  BlockLogo,
  LinkCommon,
  Page404Container,
  Page404Logo,
  Page404SubTitle,
  Page404Text,
  Page404Title,
} from "./styled";
import { WrapperCommon } from "../../styled";
import { VisionButton } from "../../Pages/Home/Vision/styled";
import { Link } from "react-router-dom";

const Page404 = () => {
  return (
    <Page404Container>
      <WrapperCommon>
        <BlockLogo>
          <Page404Logo>
            <img
              src="/assets/images/Common/logo.png"
              width={177}
              alt="Wic Chain"
            />
          </Page404Logo>
          <div className="number-zero">
            <img src="/assets/images/Common/zero.png" width={209} alt="" />
          </div>
        </BlockLogo>
        <Page404Title>
          <img src="/assets/images/Common/404.png" width={623} alt="404" />
        </Page404Title>
        <Page404SubTitle>Oops, you’ve lost in space</Page404SubTitle>
        <Page404Text>
          We can’t find the page that you’re looking for...
        </Page404Text>
        <LinkCommon>
          <Link className="link" to={"/"}>
            Go home
          </Link>
        </LinkCommon>
      </WrapperCommon>
    </Page404Container>
  );
};

export default Page404;
