import React from "react";
import {
  FooterBlockNavigation,
  FooterContaier,
  FooterGif,
  FooterListNavigation,
  FooterListSocial,
  FooterNavigationInner,
  FooterSocial,
  FooterTitle,
} from "./styled";
import { WrapperCommon } from "../../styled";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <FooterContaier data-aos="fade-up" data-aos-anchor-placement="top-bottom" id="contact">
      <WrapperCommon>
      <FooterGif>
        <img src="/assets/images/Common/Footer/footer_gif.gif" alt="" />
      </FooterGif>
        <Link to="#" className="logo">
          <img
            src="/assets/images/Common/Footer/footer_logo.png"
            alt="Wic CHAIN"
            width={164}
            height={53}
            loading="eager"
          />
        </Link>
        <FooterBlockNavigation>
          <FooterNavigationInner>
            <FooterTitle>Quick Link</FooterTitle>
            <FooterListNavigation>
              {FooterNavigation.map((item: any, index: number) => (
                <li key={index}>
                  <Link to={item.text}>{item.text}</Link>
                </li>
              ))}
            </FooterListNavigation>
          </FooterNavigationInner>
          <FooterNavigationInner>
            <FooterTitle>Developer Resource</FooterTitle>
            <FooterListNavigation>
              {FooterNavigation2.map((item: any, index: number) => (
                <li key={index}>
                  <Link to={item.text}>{item.text}</Link>
                </li>
              ))}
            </FooterListNavigation>
          </FooterNavigationInner>
          <FooterNavigationInner>
            <FooterTitle>Terms & Legal</FooterTitle>
            <FooterListNavigation>
              {FooterNavigation3.map((item: any, index: number) => (
                <li key={index}>
                  <Link to={item.text}>{item.text}</Link>
                </li>
              ))}
            </FooterListNavigation>
          </FooterNavigationInner>
        </FooterBlockNavigation>
        <FooterSocial>
          <FooterTitle>Contact Us</FooterTitle>
          <FooterListSocial>
            {listSocial.map((item: any, index: number) => (
              <li key={index}>
                <Link to={item.href} target="_blank">
                  <img src={item.icon} alt="Social" />
                </Link>
              </li>
            ))}
          </FooterListSocial>
        </FooterSocial>
      </WrapperCommon>
    </FooterContaier>
  );
};

const FooterNavigation = [
  {
    text: "Home",
  },
  {
    text: "About Us",
  },
  {
    text: "Blog/News",
  },
  {
    text: "Roadmap",
  },
  {
    text: "Whitepaper",
  },
];

const FooterNavigation2 = [
  {
    text: "Documentation",
  },
  {
    text: "API Access",
  },
  {
    text: "Github Repository",
  },
  {
    text: "Developer Group",
  },
];
const FooterNavigation3 = [
  {
    text: "Privacy Policy",
  },
  {
    text: "Terms of Service",
  },
  {
    text: "Disclaimer",
  },
];

const listSocial = [
  // {
  //   href: '#',
  //   icon: "/assets/images/Common/Footer/footer_social_01.svg",
  // },
  {
    href: 'https://x.com/WicChain',
    icon: "/assets/images/Common/Footer/footer_social_04.svg",
  },
  {
    href: 'https://t.me/wicchain_announcement',
    icon: "/assets/images/Common/Footer/footer_social_03.svg",
  },
  {
    href: 'https://t.me/wicchain_community',
    icon: "/assets/images/Common/Footer/footer_social_03.svg",
  },
  {
    type: 'email',
    href: 'mailto:wic.network2024@gmail.com',
    icon: "/assets/images/Common/Footer/footer_social_02.svg",
  },
  // {
  //   href: '#',
  //   icon: "/assets/images/Common/Footer/footer_social_05.svg",
  // },
];
export default Footer;
