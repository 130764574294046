import styled from "styled-components";
import { TitleContainer } from "../../../Component/Title/styled";
import { TextCommon } from "../../../styled";

export const TokenomicsContainer = styled.div`
  padding-top: 250px;
  position: relative;
  z-index: 4;
  &:before {
    content: "";
    position: absolute;
    bottom: -221px;
    left: 0;
    width: 100%;
    height: 220px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0));
  }
  ${TitleContainer} {
    width: 80%;
    line-height: 1;
  }
  ${TextCommon} {
    text-align: left;
    margin-bottom: 30px;
    width: 85%;
  }
  @media screen and (max-width: 1024px) {
    padding-top: 85px;
    &:before {
      content: none;
    }
  }
`;
export const TokenomicsWrapperInner = styled.div`
  position: relative;
`;
export const TokenomicsLeft = styled.div`
  max-width: 386px;
  width: 100%;
  position: relative;
  z-index: 1;
  @media screen and (max-width: 1024px) {
    max-width: unset;
  }
`;
export const TokenomicsList = styled.ul``;
export const TokenomicsListItem = styled.li`
  border-radius: 14px;
  background: #2c2c2c;
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 20px;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  .text-left {
    padding: 2px 16px;
    border-radius: 10.25px;
    border: 1.281px solid var(--stroke-25-button, rgba(255, 255, 255, 0.4));
    background: rgba(248, 248, 248, 0.2);
    background-blend-mode: luminosity;
    box-shadow: 0px 5.125px 30.75px 0px rgba(0, 0, 0, 0.3);
    color: #fff;
    font-size: 20px;
    width: 50%;
  }
`;

export const TokenomicsRight = styled.div`
  width: 80%;
  position: absolute;
  top: 102px;
  right: -180px;
  .tokenomics-chart {
    rect {
      fill: unset;
    }
  }
  @media screen and (max-width: 1024px) {
    position: relative;
    width: 100%;
    right: 100px;
  }
`;
export const TokenomicsPercent = styled.div`
  position: absolute;
  top: -60px;
  left: 36%;
  transform: translateX(-50%);
  z-index: 2;
  text-align: center;
  .number {
    color: #FFF27A;
    font-size: 64.974px;
    font-weight: 500;
  }
  .text {
    color: #FFF27A;
    font-size: 24px;
    font-weight: 400;
    width: 100%;
    margin: 0 auto;
  }
  @media screen and (max-width: 1024px) {
    position: relative;
    transform: unset;
    margin-top: 20px;
    margin-bottom: -120px;
    z-index: 2;
    left: unset;
    transform: unset;
    top: unset;
  }
`
