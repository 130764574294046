// app.tsx
import React, { Suspense } from "react";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router } from "react-router-dom";
import RoutesComponent from "./Pages/routes"; // Make sure this path is correct based on your folder structure
import { ContextProvider } from "./Component/Context";
import LoadingV2 from "./Component/LoadingV2";

function App() {
  return (
    <ContextProvider>
      <Router>
        <Suspense fallback={<LoadingV2 />}>
          <ToastContainer />
          <RoutesComponent />
        </Suspense>
      </Router>
    </ContextProvider>
  );
}

export default App;
