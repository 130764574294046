import styled from "styled-components";

export const VisionContainer = styled.div`
  padding-top: 150px;
  @media screen and (max-width: 1024px) {
    padding-top: 85px;
  }
`;
export const VisionList = styled.ul``;
export const VisionListItem = styled.li`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background: linear-gradient(266deg, #262626 0.23%, #2e2e2e 100%);
  height: 295px;
  position: relative;
  padding: 32px;
  &:not(:last-child) {
    margin-bottom: 21px;
  }
  .content-left {
    max-width: 464px;
    width: 100%;
    position: relative;
    z-index: 2;
  }
  .text-left {
    color: rgba(255, 255, 255, 0.7);
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
  }
  .content-right {
    /* mix-blend-mode: plus-lighter; */
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .image-right {
    position: relative;
    z-index: 1;
  }
  &.is-reverst {
    .content-left {
      order: 2;
      margin-left: auto;
    }
    .content-right {
      left: 0;
    }
  }
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    height: unset;
    padding: 16px 8px 0;
    min-height: 453px;
    .content-right {
      position: absolute;
      right: unset;
      left: 0;
      top: 0;
      transform: unset;
    }
    .content-right {
      pointer-events: none;
      width: 100%;
      height: 498px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &.is-reverst {
      min-height: 376px;
      .content-right {
        left: 0;
        top: unset;
        bottom: 0;
      }
    }
  }
`;
export const VisionButton = styled.button`
  margin-top: 20px;
  font-family: "Radio Canada Big";
  font-size: 15px;
  font-weight: 500;
  border-radius: 8px;
  color: rgb(136, 136, 136);
  border: 1px solid rgba(121, 121, 121, 0.5);
  background: rgba(71, 71, 71, 0.13);
  box-shadow: rgba(255, 255, 255, 0.2) 0px 1px 5px 0px inset,
    rgba(255, 255, 255, 0.2) 0px -1px 5px 0px inset;
  backdrop-filter: blur(2.25px);
  width: 144px;
  height: 48px;
  position: relative;
  padding-right: 25px;
  transition: 0.3s linear;
  &:after {
    position: absolute;
    content: "";
    top: 50%;
    margin-top: -13px;
    right: 12px;
    background: url("/assets/images/Common/Header/header_icon_arrow.png") center /
      cover no-repeat;
    width: 24px;
    height: 24px;
  }
  &:hover {
    color: rgb(255, 255, 255);
    border: 1px solid rgb(230, 229, 229);
    background: linear-gradient(rgb(110, 86, 37) 0%, rgb(236, 181, 0) 100%);
    box-shadow: rgba(217, 217, 217, 0.9) 0px 3px 5px 0px inset,
      rgba(217, 217, 217, 0.9) 0px -1px 5px 0px inset;
  }
`;
