import styled from "styled-components";
import { WrapperCommon } from "../../../styled";
import { TitleContainer } from "../../../Component/Title/styled";

export const KeyvisualContainer = styled.div`
  min-height: 675px;
  height: 100%;
  position: relative;
  z-index: 1;
  overflow: hidden;
  width: calc(100% - 20px);
  border-radius: 8px;
  margin: 0 auto;
  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    background: url("/assets/images/Keyvisual/background_decor_01.png") center /
      cover no-repeat;
    width: 400px;
    height: 316px;
    mix-blend-mode: lighten;
    pointer-events: none;
  }
  &:after {
    z-index: -1;
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    background: linear-gradient(107deg, rgba(0, 0, 0, 0.00) 5.36%, #1C1B1B 68.31%);
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 1024px) {
    padding: 50px 0;
    ${TitleContainer} {
      width: 70%;
      margin-bottom: 25px;
    }
    @media screen and (max-width: 1024px) {
      height: 675px;
      padding-bottom: 0;
      ${WrapperCommon} {
        height: 675px;
      }
    }
  }
`;
export const KeyvisualWrapperInner = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  min-height: 675px;
  &:after {
    position: absolute;
    content: "";
    top: -1430px;
    right: -1440px;
    pointer-events: none;
    width: 902.234px;
    height: 2012.418px;
    transform: rotate(94.734deg);
    flex-shrink: 0;
    background-color: #ffae00;
    filter: blur(250px);
  }
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    height: 625px;
    min-height: unset;
  }
`;
export const KeyvisualContentLeft = styled.div`
  @media screen and (max-width: 1024px) {
    display: flex;
    gap: 20px;
    max-width: 90%;
    margin: 0 auto;
  }
`;
export const KeyvisualLeft = styled.div`
  .logo-chain,
  .logo-left {
    margin-bottom: 20px;
  }
  .logo-chain {
    mix-blend-mode: luminosity;
  }
  .text-left {
    color: rgba(255, 255, 255, 0.7);
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
  }
  @media screen and (min-width: 1024px) {
    width: 103%;
  }
  @media screen and (max-width: 1024px) {
    .logo-left {
      flex-shrink: 0;
      width: 85px;
      height: 85px;
    }
    .logo-chain {
      margin-bottom: 10px;
    }
    .text-left {
      font-size: 15px;
    }
  }
`;
export const KeyvisualRight = styled.div`
  max-width: 903px;
  width: 100%;
  .image-decor-01 {
    mix-blend-mode: saturation;
    position: absolute;
    right: -253px;
    top: 0;
  }
  .image-gif {
    position: absolute;
    mix-blend-mode: screen;
    right: -167px;
    top: 16px;
    &:after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #ffd373;
      mix-blend-mode: darken;
    }
  }
  .logo-middle {
    position: absolute;
    right: -14px;
    top: 172px;
    width: 288px;
    height: 288px;
    border-radius: 50%;
    transition: all 0.26s ease;
    animation: bounce-in-desktop 2s ease infinite;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  @media screen and (min-width: 1024px) {
    .image-gif {
      right: -246px;
      top: -70px;
      width: 68%;
      img {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .image-decor-01,
    .image-gif,
    .logo-middle {
      position: absolute;
      right: unset;
      left: 50%;
      bottom: 0;
      top: unset;
      transform: translateX(-50%);
    }
    .image-decor-01 {
      width: 430px;
      height: 355px;
      bottom: 29px;
      left: 50%;
    }
    .image-gif {
      width: 406px;
      height: 406px;
      bottom: 0;
      left: 50%;
      img {
        width: 100%;
      }
    }
    .logo-middle {
      width: 210px;
      height: 210px;
      bottom: 95px;
      left: 50%;
      animation: unset;
      background: linear-gradient(
        180deg,
        #ffff8f 13.67%,
        #ffe983 30.95%,
        #f8d063 49.4%,
        #ffd600 69.03%,
        #fddb74 92.19%
      );
      filter: drop-shadow(0px 0px 60px #ffc300);
    }
  }
  @keyframes bounce-in-desktop {
    0% {
      transform: scale(1);
      background: linear-gradient(
        180deg,
        #ffff8f 13.67%,
        #ffe983 30.95%,
        #f8d063 49.4%,
        #ffd600 69.03%,
        #fddb74 92.19%
      );
      filter: drop-shadow(0px 0px 60px #ffc300);
    }
    50% {
      transform: scale(1.1);
    }
    70% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
      background: linear-gradient(
        180deg,
        #ffff8f 13.67%,
        #ffe983 30.95%,
        #f8d063 49.4%,
        #ffd600 69.03%,
        #fddb74 92.19%
      );
      filter: drop-shadow(0px 0px 60px #ffc300);
    }
  }
  @keyframes bounce-in-mobile {
    0% {
      transform: scale(1) translateX(-50%);
      background: linear-gradient(
        180deg,
        #ffff8f 13.67%,
        #ffe983 30.95%,
        #f8d063 49.4%,
        #ffd600 69.03%,
        #fddb74 92.19%
      );
      filter: drop-shadow(0px 0px 60px #ffc300);
    }
    50% {
      transform: scale(1.1) translateX(-50%);
    }
    70% {
      transform: scale(1.1) translateX(-50%);
    }
    100% {
      transform: scale(1) translateX(-50%);
      background: linear-gradient(
        180deg,
        #ffff8f 13.67%,
        #ffe983 30.95%,
        #f8d063 49.4%,
        #ffd600 69.03%,
        #fddb74 92.19%
      );
      filter: drop-shadow(0px 0px 60px #ffc300);
    }
  }
`;

export const IconBottom = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`;
