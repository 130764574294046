import React, { useContext, useEffect, useState } from "react";
import {
  ButtonLaunchApp,
  ButtonMenu,
  ContentButtonMenu,
  ContentNavigation,
  HeaderContainer,
  HeaderContent,
  ListNavigation,
  ListNavigationItem,
} from "./styled";
import { WrapperCommon } from "../../styled";
import { Link, NavLink } from "react-router-dom";
import { ContextProviderWrapper } from "../Context";

const Header = () => {
  const { openMenu, setOpenMenu } = useContext(ContextProviderWrapper)!;
  const [activeMenu, setActiveMenu] = useState(false);
  const [activeText, setActiveText] = useState("");
  const handleShowMenu = () => {
    setActiveMenu(!activeMenu);
    setOpenMenu(!openMenu);
  };

  //handle active Menu
  useEffect(() => {
    if (activeMenu) {
      document.body.classList.add("open-menu");
    } else {
      document.body.classList.remove("open-menu");
    }
  }, [activeMenu]);

  //handle close menu
  const handleCloseMenu = () => {
    setOpenMenu(false);
    setActiveMenu(false);
  };

  //handle active text
  const handleActiveText = (text: string) => {
    setActiveText(text);
  };

  return (
    <HeaderContainer
      className="flex py-4 px-4 sm:px-10 bg-white font-[sans-serif] min-h-[70px] tracking-wide relative z-50"
      data-aos="fade-in"
    >
      <WrapperCommon className="flex flex-wrap items-center justify-between gap-0.5">
        <HeaderContent>
          <Link to="javascript:void(0)">
            <img
              src="/assets/images/Common/Header/header_logo.png"
              alt="logo"
              className="logo"
            />
          </Link>
        </HeaderContent>

        <ContentNavigation className={`${activeMenu ? "show-navigation" : ""}`}>
          <ListNavigation
            className={`lg:flex gap-x-6 max-lg:space-y-3 max-lg:fixed max-lg:bg-white max-lg:w-1/2 max-lg:min-w-[300px] max-lg:top-0 max-lg:left-0 max-lg:p-6 max-lg:h-full max-lg:shadow-md max-lg:overflow-auto z-50`}
          >
            {listMenu.map((item: any, index: number) => (
              <ListNavigationItem
                key={index}
                className="max-lg:border-b border-gray-300 max-lg:py-3"
              >
                {/* <Link to={item.link} className="text-[#555] block text-[15px]">
                  {item.text}
                </Link> */}
                <a
                  href={item.link}
                  className={`text-[#555] block text-[15px] ${activeText === item.text ? 'active' : ''}`}
                  onClick={() => {
                    handleCloseMenu();
                    handleActiveText(item.text);
                  }}
                >
                  {item.text}
                </a>
              </ListNavigationItem>
            ))}
          </ListNavigation>
        </ContentNavigation>

        <div className="flex max-lg:ml-auto space-x-3">
          <ButtonLaunchApp className="button-launch">
            <a href="https://app.wicchain.net/" target="_blank">Launch App</a>
          </ButtonLaunchApp>
          <ContentButtonMenu className="lg:hidden">
            <ButtonMenu
              className={`rounded-lg ${activeMenu ? "active" : ""}`}
              onClick={handleShowMenu}
            >
              <span></span>
              <span></span>
              <span></span>
            </ButtonMenu>
          </ContentButtonMenu>
        </div>
      </WrapperCommon>
    </HeaderContainer>
  );
};

const listMenu = [
  {
    text: "Overview",
    link: "#overview",
  },
  {
    text: "About",
    link: "#about",
  },
  {
    text: "Features",
    link: "#features",
  },
  {
    text: "Token",
    link: "#token",
  },
  {
    text: "Roadmap",
    link: "#roadmap",
  },
  {
    text: "Team",
    link: "#team",
  },
  {
    text: "FAQ",
    link: "#faq",
  },
  {
    text: "Contact",
    link: "#contact",
  },
  // {
  //   text: "My Account",
  //   link: "/account",
  // },
];

export default Header;
