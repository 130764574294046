import styled from "styled-components";

export const AppContainer = styled.div``
export const WrapperCommon = styled.div`
  max-width: 1121px;
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 1024px) {
    padding-left: 12px;
    padding-right: 12px;
  }
`

export const TextCommon = styled.p`
  color: rgba(255, 255, 255, 0.70);
  text-align: center;
  font-size: 18px;
  letter-spacing: 0.28px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  @media screen and (max-width: 1024px) {
    font-size: 15px;
  }
`