import styled from "styled-components";
import { WrapperCommon } from "../../styled";

export const FooterContaier = styled.div`
  padding: 70px 0 30px;
  position: relative;
  overflow: hidden;
  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #1e1e1e url("/assets/images/Common/Footer/footer_bg.png") center /
      cover no-repeat;
    border-radius: 10px;
    mix-blend-mode: luminosity;
  }
  .logo {
    display: inline-block;
    margin-bottom: 30px;
    position: relative;
    z-index: 1;
  }
  ${WrapperCommon} {
    position: relative;
  }
  @media screen and (max-width: 1024px) {
    .logo {
      padding: 0 20px;
      margin-bottom: 150px;
    }
  }
`;
export const FooterBlockNavigation = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 117px;
  margin-bottom: 70px;
  position: relative;
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  @media screen and (max-width: 1024px) {
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px;
    padding: 0 20px;
    // none
    display: none;
  }
`;
export const FooterTitle = styled.h3`
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
  position: relative;
  padding-left: 13px;
  &:before {
    position: absolute;
    content: "";
    top: 50%;
    margin-top: -6px;
    left: 0;
    background: url("/assets/images/Common/Footer/footer_icon_arrow.svg") center /
      cover no-repeat;
    width: 6px;
    height: 6px;
  }
`;
export const FooterNavigationInner = styled.div``;
export const FooterListNavigation = styled.ul`
  li {
    &:not(:last-child) {
      margin-bottom: 7px;
    }
    a {
      color: rgba(255, 255, 255, 0.6);
      font-size: 16px;
      transition: color 0.26s ease;
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      &:hover {
        color: #fff;
      }
    }
  }
`;
export const FooterSocial = styled.div`
  border-radius: 4.928px;
  background: #2e2e2e;
  padding: 20px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  ${FooterTitle} {
    margin-bottom: 0;
  }
  @media screen and (max-width: 1024px) {
    padding: 15px;
  }
`;
export const FooterListSocial = styled.ul`
  display: flex;
  align-items: center;
  gap: 15px;
  a {
    display: inline-block;
    transition: opacity 0.26s ease;
    &:hover {
      opacity: 0.7;
    }
  }
`;

export const FooterGif = styled.div`
  position: absolute;
  background-color: lightgray;
  mix-blend-mode: screen;
  /* right: -130px; */
  left: 50%;
  transform: translateX(-50%);
  //
  top: -80px;
  z-index: 1;
  pointer-events: none;
  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffd373;
    mix-blend-mode: darken;
  }
  @media screen and (max-width: 1024px) {
    right: -160px;
    top: -140px;
    //
    left: unset;
    transform: unset;
  }
`;
