import React from "react";
import {
  ChooseContainer,
  ChooseHeading,
  ChooseHeadingImage,
  ChooseList,
  ChooseListItem,
  ChooseText,
  HeadingLeft,
  WrapperInner,
} from "./styled";
import { WrapperCommon } from "../../../styled";
import TitleCommon from "../../../Component/Title";

const Choose = () => {
  return (
    <ChooseContainer id="about">
      <WrapperCommon className="border border-solid rounded-2xl">
        <WrapperInner className="rounded-2xl">
          <ChooseHeading className="flex justify-between sm:items-start" data-aos="fade-up">
            <HeadingLeft className="lg:w-4/12">
              <TitleCommon text={"Why Choose WIC Chain?"} />
              <ChooseText>
                Experience unmatched scalability, low fees, and seamless
                integration with full EVM compatibility.
              </ChooseText>
            </HeadingLeft>
            <ChooseHeadingImage>
              <img
                src="/assets/images/Choose/choose_img_01.png"
                width={165}
                alt="Choose"
              />
            </ChooseHeadingImage>
          </ChooseHeading>
          <ChooseList className="flex flex-wrap justify-end">
            {chooseList.map((item:any, index:number)=>(
              <ChooseListItem key={index} className="w-4/12" data-aos="fade-up" data-aos-delay={item.delay}>
                <div className="icon">
                  <img src={item.icon} alt={item.title} width={39} height={40} />
                </div>
                <h3 className="title">{item.title}</h3>
                <p className="text">{item.text}</p>
              </ChooseListItem>
            ))}
          </ChooseList>
        </WrapperInner>
      </WrapperCommon>
    </ChooseContainer>
  );
};

const chooseList = [
  {
    icon: '/assets/images/Choose/choose_icon_01.png',
    title: 'EVM Compatibility',
    text: 'Seamlessly deploy Ethereum-based dApps with full EVM support.',
    delay: '0',
  },
  {
    icon: '/assets/images/Choose/choose_icon_02.png',
    title: 'High Scalability',
    text: 'Handle high transaction volumes with low latency.',
    delay: '100',
  },
  {
    icon: '/assets/images/Choose/choose_icon_03.png',
    title: 'Low Transaction Fees',
    text: 'Enjoy fast and affordable transactions for all users.',
    delay: '200',
  },
  {
    icon: '/assets/images/Choose/choose_icon_04.png',
    title: 'Cross-Chain Interoperability',
    text: 'Effortlessly transfer assets across multiple blockchains.',
    delay: '300',
  },
  {
    icon: '/assets/images/Choose/choose_icon_05.png',
    title: 'Decentralized Security',
    text: 'Built on a robust and secure consensus mechanism for maximum protection.',
    delay: '400',
  },
]

export default Choose;
