import React, { useContext } from "react";
import {
  RoadmapContainer,
  RoadmapContent,
  RoadmapLine,
  RoadmapList,
  RoadmapListItem,
  RoadmapListMobile,
} from "./styled";
import { TextCommon, WrapperCommon } from "../../../styled";
import TitleCommon from "../../../Component/Title";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { ContextProviderWrapper } from "../../../Component/Context";

const Roadmap = () => {
  const { isDesktop } = useContext(ContextProviderWrapper)!;
  return (
    <RoadmapContainer id="roadmap">
      <WrapperCommon>
        <div data-aos="flip-up">
          <TitleCommon text="Roadmap" />
          <TextCommon>
            Welcome to the roadmap of WICCHAIN, where we chart our course
            through innovative blockchain technology development and strategic
            market expansion.
          </TextCommon>
        </div>
        <RoadmapContent data-aos="zoom-in">
          <RoadmapLine></RoadmapLine>
          {isDesktop ? (
            <RoadmapList>
              {roadmap.map((item: any, index: number) => (
                <RoadmapListItem key={index}>
                  <p className="year">{item.year}</p>
                  <h3 className="title">{item.title}</h3>
                  <ul className="list-text">
                    {item.subList.map((text: any, index: number) => (
                      <li key={index} className="text">
                        {text}
                      </li>
                    ))}
                  </ul>
                </RoadmapListItem>
              ))}
            </RoadmapList>
          ) : (
            <RoadmapListMobile>
              <Swiper
                slidesPerView={2}
                spaceBetween={10}
                centeredSlides={true}
                grabCursor={true}
                initialSlide={0}
                pagination={{
                  clickable: true,
                }}
                className="swiper-mobile"
              >
                {roadmap.map((item: any, index: number) => (
                  <SwiperSlide className="asdas">
                    <RoadmapListItem key={index}>
                      <p className="year">{item.year}</p>
                      <h3 className="title">{item.title}</h3>
                      <ul className="list-text">
                        {item.subList.map((text: any, index: number) => (
                          <li key={index} className="text">
                            {text}
                          </li>
                        ))}
                      </ul>
                    </RoadmapListItem>
                  </SwiperSlide>
                ))}
              </Swiper>
            </RoadmapListMobile>
          )}
        </RoadmapContent>
      </WrapperCommon>
    </RoadmapContainer>
  );
};

const roadmap = [
  {
    year: "Q4, 2024",
    // title: "Start Applying AI",
    subList: [
      "Launch WICCHAIN DApp",
      "Building Community & Onboarding Partners",
      "Airdrop Campaign",
    ],
  },
  {
    year: "Q1, 2025",
    // title: "AI With Social & Game FI",
    subList: [
      "Initiating WIC Bot - Tap To Earn (GameFi)",
      "Seed Round",
      "Researching & Developing Product",
    ],
  },
  {
    year: "Q2, 2025",
    // title: "AI With Social Network",
    subList: [
      "Initiating WICEYE - DEX Platform and Integrate AI market analysis",
      "Private sale",
    ],
  },
  {
    year: "Q3, 2025",
    // title: "Listing CEX",
    subList: ["Launch WICCHAIN - Layer 1 (L1) blockchain platform", "IDO"],
  },
  {
    year: "Q4, 2025",
    // title: "Listing CEX",
    subList: [
      "Listing $WIC Coin on DEX & CEX",
      "Increase users on the WIC Network",
      "DAO Governance",
    ],
  },
];

export default Roadmap;
