import React, { useContext, useEffect, useState } from "react";
import Choose from "./Choose";
import Roadmap from "./Roadmap";
import Partner from "./Partner";
import Faq from "./Faq";
import Tokenomics from "./Tokenimics";
import Keyvisual from "./Keyvisual";
import Vision from "./Vision";
import Ecosystem from "./Ecosystem";
import Features from "./Features";
import { ContentEcosytem, HomeContainer } from "./styled";
import { ContextProviderWrapper } from "../../Component/Context";
import AOS from 'aos';
import 'aos/dist/aos.css';


const Home = () => {
  const [circles, setCircles] = useState<any>([]);
  const { isDesktop } = useContext(ContextProviderWrapper)!;

  // Generate random circles
  useEffect(() => {
    const generateCircles = () => {
      const newCircles = Array.from({ length: isDesktop ? 40 : 10 }, () => ({
        size: Math.random() * 10 + 5, // random size
        top: Math.random() * 100, // random top position (percentage)
        left: Math.random() * 100, // random left position (percentage)
        duration: Math.random() * 10 + 5, // random animation duration
      }));
      setCircles(newCircles);
    };

    generateCircles();
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <HomeContainer>
      <div className="circle-container">
        {circles.map((circle: any, index: any) => (
          <div
            key={index}
            className="circle"
            style={{
              width: `${circle.size}px`,
              height: `${circle.size}px`,
              top: `${circle.top}%`,
              left: `${circle.left}%`,
              animationDuration: `${circle.duration}s`,
              background: `#fbe947`
            }}
          ></div>
        ))}
      </div>
      <Keyvisual />
      <Vision />
      <Choose />
      <Features />
      <Tokenomics />
      <ContentEcosytem>
        <Roadmap />
        {/* <Ecosystem /> */}
        <Partner />
        <Faq />
      </ContentEcosytem>
    </HomeContainer>
  );
};

export default Home;
