import styled from "styled-components";

export const LoadingV2Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background: #111;
  .block-loading {
    width: 273px;
    height: 273px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-bottom: 15px;
    position: relative;
    &::before,
    &:after {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 120%;
      height: 120%;
    }
    &:before {
      position: absolute;
      content: "";
      background: url("/assets/images/Common/loading.gif") center / cover
        no-repeat;
      border-radius: 50%;
      mix-blend-mode: color-dodge;
      z-index: 0;
    }
    &:after {
      position: absolute;
      content: "";
      background: #ffff8f;
      mix-blend-mode: hue;
      z-index: 1;
      border-radius: 50%;
    }
    img {
      border-radius: 50%;
      position: relative;
      z-index: 11;
      animation: animate 4s linear infinite;
    }
  }
  .text {
    background: linear-gradient(
      270deg,
      #fedf7a -106.16%,
      #ecb131 -49.58%,
      #ffff8f 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-family: "Radio Canada Big";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    text-transform: capitalize;
  }
  @keyframes animate {
    0% {
      transform: translateY(0);
      box-shadow: 0px 0px 0px 0px #FFC300;
    }
    50% {
      transform: translateY(-25px);
      box-shadow: 0px 0px 80px 0px #FFC300;
    }
    100% {
      transform: translateY(0);
      box-shadow: 0px 0px 0px 0px #FFC300;
    }
  }
`;
